body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* light mode colors: */
/* #root {
  background-color: #FAF7F5;
  color: #393939;
} */

/* HOME PAGE */
.intro {
  height: 79vh;
}

.intro-col {
  z-index: 5;
}

.intro-title {
  margin-top: 50%;
  color: #FAF7F5;
  font-size: 60px;
  font-weight: 700;
}

.intro-text {
  color: #eff6e0;
}

.blob1-wrapper {
  position: relative;
}

.blob1 {
  margin: 0;
  position: absolute;
  z-index: -1;
  filter: blur(40px);
  opacity: .7;
}

.blob2-wrapper {
  position: relative;
}

.blob2 {
  margin: 0;
  position: absolute;
  z-index: -3;
  filter: blur(40px);
  opacity: .8;
}

/* PORTFOLIO */
.portfolio-items-container {
  flex-direction: column;
}

.portfolio-img {
  border-radius: 20px;
  width: 100%;
}

.project-links {
  justify-content: space-evenly;
}

/* RESUME */
.resume-contact {
  text-align: right;
}

/* ABOUT */
.portrait {
  width: 250px;
  border-radius: 3px;
}

.codepen {
  fill: #FFF;
  height: 24px;
  width: 32.5px;
}

/* FOOTER */
.footer {
  text-align: center;
  width: 100%;
  z-index: 1;
  opacity: 0.65;
}

/* MEDIA QUERIES */
@media (min-width: 990px) {
  .intro-title {
    margin-top: 30%;
  }
}

@media (max-width: 650px) {
  .intro-title {
    font-size: 40px;
  }
}