body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.credits {
  text-align: center;
}

/* CIRCUIT TIMER */

.timer-hero {
  background-color: #242424;
}

/* WORDPRESS CASE STUDY */

.wp-hero {
  text-shadow: -3px 3px 0px rgba(11, 20, 31, 1);
}

.sob-button {
  max-width: 60%;
  margin-inline: auto;
}

/* AWS CASE STUDY */

.corners {
  border-radius: 10px;
}
.aws-hero {
  background-image: url("https://images.unsplash.com/photo-1488812690953-601000f207e4?q=80&w=2090&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
}

.aws-tech {
  background-color: rgba(53, 69, 68, 0.8);
}

.aws-img {
  max-height: 400px;
  max-width: 100%;
}

/* ANGULAR MOVIE CLIENT CASE STUDY */

.angular-movie-hero {
  background-image: url("/public/liam-mcgarry-unsplash.png");
  background-position: center;
}

.angular-documentation {
  width: 100%;
}

/* MEET CASE STUDY */
.meet-hero {
  background-image: url("https://images.unsplash.com/photo-1516738901171-8eb4fc13bd20?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-position: right;
  border-radius: 3px;
}

.meet-cs-screenshot {
  width: 80%;
}

.meet-dividers {
  background-color: rgb(158, 198, 214);
  margin-inline: 20%!important;
  border-radius: 3px;
}

.meet-logo-meet {
  font-size: 75px;
  font-weight: 600;
  -webkit-text-stroke: 2px white;
  color: black;
  text-shadow: 
    3px 3px 0 white,
    -1px -1px 0 white,
    1px -1px 0 white,
    -1px 1px 0 white,
    1px 1px 0 white;
}

.meet-logo-subtitle {
  overflow: hidden;
  border-right: .15em solid rgb(142, 178, 177);
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  animation: 
    typing 3.5s steps(16, end),
    blink-caret .75s step-end infinite;
}

.meet-hero-credit {
  font-size: smaller;
}

/* MOVIE CASE STUDY */
.hero {
  background-image: url("/public/bw-theater.jpg");
  background-position: center;
}

.mini-dividers {
  margin-left: 20%!important;
}

.mini-dividers-2 {
  margin-left: 40%!important;
}

.mini-dividers-3 {
  margin-left: 60%!important;
}

.mini-dividers-4 {
  margin-left: 80%!important;
}

.bi-arrow-right {
  margin-inline: 10px;
}

.popopolis-logo {
  font-family: 'Limelight', sans-serif;
}

.carousel-img {
  max-width: 600px;
  max-height: 500px;
}

.carousel-item {
  text-align: center;
}

/* CHAT CASE STUDY */

.chat-hero {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(71,64,86,1) 56%, rgba(138,149,165,1) 100%);
  border-radius: 20px;
}

.chat-tech {
  max-width: 500px;
  background-color: lightblue;
  color: rgb(0, 0, 0);
  margin-right: 20%!important;
  border-radius: 20px;
  border-bottom-right-radius: 0px;
}

.chat-left {
  max-width: 500px;
  background-color: lightgray;
  color: rgb(0, 0, 0);
  margin-left: 20%!important;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  left: -0.35rem;
}